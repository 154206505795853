<template>
    <div>
        <!-- error handelr -->
        <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
            variant="danger">
            <h4 class="alert-heading">Alert</h4>
            <div class="alert-body">
                <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{ value }}</li>
                </ul>
            </div>
        </b-alert>
        <b-card title="Notifications">
            <b-row class="ml-auto">
                <b-col md="4" class="my-1">
                    <b-form-group
                        label="Sort By"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="sortBySelect"
                        class="mb-0 h-100"
                    >
                        <b-input-group size="sm">
                        <b-form-select
                            id="sortBySelect"
                            @change="fetchData"
                            v-model="sortBy"
                            :options="sortOptions"
                            class="w-75"
                        >
                        </b-form-select>
                        <b-form-select
                            @change="fetchData"
                            v-model="sortDesc"
                            size="sm"
                            :disabled="!sortBy"
                            class="w-25"
                        >
                            <option :value="false">
                            Asc
                            </option>
                            <option :value="true">
                            Desc
                            </option>
                        </b-form-select>
                        </b-input-group>
                    </b-form-group>
                    </b-col>
                <!-- sorting -->
                <b-col md="12" class="my-1">
                    <b-table id="AdminsTable" ref="admint" striped hover responsive show-empty :busy.sync="isBusy"
                        class="position-relative text-bold"  :items="$store.state.notifications.notifications.data"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :fields="fields">
                        <template #cell(id)="data">

                            {{(currentPage*perPage)-perPage + data.index+1}}
                        </template>

                        
                        <template #cell(created_at)="data">
                            <b-badge pill variant="light-primary" v-if="data.item.created_at">
                                {{ data.item.created_at | formatDateAgo }}
                            </b-badge> 

                            <b-badge pill variant="light-warning" v-else>
                                            الان
                            </b-badge>
                        </template>

                        <!-- show actions -->
                        <template #cell(show)="data">
                            <b-button style="padding: 7px; margin: 6px; width: 35px; height: 35px;" 
                                class="btn-icon rounded-circle" :to="{ path: data.item.data.dir_vednor_show ?  data.item.data.dir_vednor_show :  data.item.dir_vednor_show }"
                                @click.prevent="makeRead(data.item.id, data.item.data.dir_vednor_show ?  data.item.data.dir_vednor_show :  data.item.dir_vednor_show  ,data.index)" variant="success">
                                <feather-icon style="width: 18px; height: 18px;" icon="EyeIcon" />
                            </b-button>
                            <span class="badge badge-dot" v-if="data.item.read_at == null"></span>
                        </template>

                    </b-table>
                </b-col>
            </b-row>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                    id="perPageSelect"
                    @change="fetchData()"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                />
                </b-form-group>
                <!-- pagination -->
                <div>
                <b-pagination
                    @input="fetchData()"
                    aria-controls="notificationsuser"
                    v-model="currentPage"
                    :total-rows="$store.state.notifications.totalRows"
                    :per-page="perPage"
                    align="center"
                    class="my-0"
                />
                </div>
            </b-card-body>


        </b-card>

    </div>
</template>

<script>

export default {
    name: 'notificationsuser',

    data() {
        return {

          
            fields: [
                {
                  label: "كود",
                  key: "id",
                },
                {
                    key: 'data.data',
                    label: 'التنبية',
                },
                {
                    key: 'created_at',
                    label: 'انشا في',
                    sortable: true,
                },
                {
                  label: "انشا في",
                  key: "created_at",
                },
                {
                  label: "عرض",
                  key: "show",
                },
             
            ],
            showDismissibleAlert: false,
            errors_back: [],
            isBusy: false,
            perPage: 10,
            pageOptions: [10,25, 50, 100],
            currentPage: 1,
            sortBy: 'created_at',
            sortDirection: "desc",
            sortDesc: true,

        };
    },


    computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
            }))
        },
        rows() {
        
        return this.$store.state.notifications.totalRows
        },
    },

    created() {
        let {  perPage, currentPage , sortDirection ,sortBy } = this;

                if (this.sortDesc == true) {
                sortDirection = "desc";
                } else {
                    sortDirection = "asc";
                }
             this.$store.dispatch('notifications/getnotifications',{perPage, currentPage,sortDirection ,sortBy});
    },
    methods: {
     async  makeRead(notification_id, dir_vednor_show, index) {
            await this.$store.dispatch('notifications/makeRead',{notification_id, dir_vednor_show, index});
        },


        fetchData(){
            let {  perPage, currentPage ,sortBy , sortDirection } = this;

            if (this.sortDesc == true) {
            sortDirection = "desc";
            } else {
                sortDirection = "asc";
            }   

            axios.get('notification/' + perPage  + "/sortBy-" +  sortBy + "-" +  sortDirection + '?page=' + currentPage )
            .then((response) => {
                this.$store.commit('notifications/getNotification', response.data.data.notifications)
                this.$store.commit('notifications/getunreadNotifications', response.data.data.unreadNotifications)

            }).catch((err) => {
                this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                        this.errors_back.push({
                            error: err.response.data.data,
                        })
                        } else {
                        this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                        error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
            })
        }


    },
};
</script>

<style lang="scss" scoped>
.badge.badge-dot {
    position: absolute;
    right: 20px;
    background-color: #7367f0;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    vertical-align: middle;
}

.font-9{
    font-size: 9px;
}
</style>